
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'layout.customer' }" class=""
                    >Customers</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Customer View
                </li>
              </ol>
            </nav>
          </div>
          <!-- <div
              @click="handleBackBtn"
              class="
                btn btn-secondary btn-sm
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="ml-2">Back</span>
            </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5" v-if="this.user.profile">
        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div>
                <ul class="list-style-1 mb-0">
                  <li
                    class="
                      list-item
                      d-flex
                      justify-content-between
                      align-items-center
                      mt-3
                      mb-3
                    "
                  >
                    <div class="d-flex align-items-center">
                      <h5 class="font-weight-bold ml-2">
                        {{
                          this.user.profile.firstName +
                          " " +
                          this.user.profile.lastName
                        }}
                      </h5>
                    </div>

                    <router-link :to="{ name: 'customer.customeredit' }">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="list-group-item">
              <table class="table table-borderless mb-0">
                <thead>
                  <h6 class="font-weight-bold mt-2 mb-2">Profile</h6>
                </thead>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Phone</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.user.profile.phone ? this.user.profile.phone : "-"
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Email</p>
                  </td>
                  <td v-if="this.user.profile.hasDummyEmail">
                    <p
                      class="mb-0 dummy-email"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      {{
                        this.user.profile.email ? this.user.profile.email : "-"
                      }}
                    </p>
                  </td>
                  <td v-else>
                    <p class="mb-0" data-toggle="tooltip" data-placement="top">
                      {{
                        this.user.profile.email ? this.user.profile.email : "-"
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Firebase UID</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.user.profile.firebaseUid
                          ? this.user.profile.firebaseUid
                          : "-"
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Referral Code</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.user.profile.referralCode
                          ? this.user.profile.referralCode
                          : "-"
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Referrer Code</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.user.profile.referrer
                          ? this.user.profile.referrer.code
                          : "-"
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Date Joined</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.user.profile.dateJoined | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Last Login</p>
                  </td>
                  <td v-if="this.user.profile.lastLogin">
                    <p class="mb-0">
                      {{ this.user.profile.lastLogin | formatDate }}
                    </p>
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Notes</p>
                  </td>
                  <td v-if="this.notes.length > 0">
                    <!-- <p class="mb-0">
                      {{
                        this.user.profile.notes ? this.user.profile.notes : "-"
                      }}
                    </p> -->
                    <div
                      class="pre"
                      v-for="(note, index) in notes"
                      :key="index"
                    >
                      {{ note }}
                    </div>
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Created By Staff</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.user.profile.isCreatedByStaff ? "Yes" : "No" }}
                    </p>
                  </td>
                </tr>
                <th colspan="2" class="th-padding-class" v-if="this.user.profile.wallet">
                  <h6 class="font-weight-bold mt-2 mb-2">User Wallet Info</h6>
                </th>
                <tr v-if="this.user.profile.wallet">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Credits</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        
                        this.user.profile.wallet.balance.amount +
                        " " +
                        this.user.profile.wallet.balance.currency 
                      }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.user.profile.wallet">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Expiry On</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.user.profile.wallet.expiryDate | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.user.profile.wallet">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Updated On</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.user.profile.wallet.expiryDate | formatDate }}
                    </p>
                  </td>
                </tr>
                <th class="th-padding-class" colspan="2">
                  <h6 class="font-weight-bold mt-2 mb-2">User App Info</h6>
                </th>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Last App Open</p>
                  </td>
                  <td v-if="this.user.lastAppOpen">
                    <p class="mb-0">
                      {{ this.user.lastAppOpen.openedAt | formatDate }}
                    </p>
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Location History</p>
                  </td>
                  <td v-if="this.user.locationHistory">
                    <div id="location-history">
                      {{
                        this.user.locationHistory.lastLocation.lat.toFixed(6) +
                        ", " +
                        this.user.locationHistory.lastLocation.lng.toFixed(6)
                      }}
                    </div>
                    <button
                      class="btn btn-primary btn-sm mr-1"
                      @click="handleLocationHistoryLink('location-history')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M6 17c2.269-9.881 11-11.667 11-11.667v-3.333l7 6.637-7 6.696v-3.333s-6.17-.171-11 5zm12 .145v2.855h-16v-12h6.598c.768-.787 1.561-1.449 2.339-2h-10.937v16h20v-6.769l-2 1.914z"
                        />
                      </svg>
                      Open
                    </button>
                  </td>
                  <td v-else>-</td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body p-2">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
                mb-2
              "
            >
              <h5 class="font-weight-bold">Cars</h5>
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <search
                      :search="searchCars"
                      :placeholder="'Search'"
                      @searchFilter="handleSearchCustomerCar"
                    >
                    </search>
                  </div>

                  <div>
                    <router-link
                      :to="{ path: '/car/add/' + this.number }"
                      class="
                        btn btn-success btn-sm
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      <div class="white-space">Add Car</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive table-zoom">
              <table class="table data-table mb-0 table-customer">
                <thead class="table-color-heading">
                  <tr class="">
                    <th scope="col">Car</th>
                    <th scope="col">Classification</th>
                    <th scope="col">Plate Number</th>
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="white-space-no-wrap transition-row"
                    v-for="userCar in userCars.edges"
                    :key="userCar.node.id"
                  >
                    <td class="table-font">
                      <div class="data-content">
                        {{
                          userCar.node.model.make.name +
                          " " +
                          userCar.node.model.name
                        }}
                      </div>
                    </td>
                    <td class="table-font">
                      {{ userCar.node.classification }}
                    </td>
                    <td class="table-font" v-if="userCar.node.licensePlate">
                      <div title="Click To Copy">
                        <div
                          @click.prevent="
                            copyLicensePlateToClipboard(
                              userCar.node.licensePlate
                            )
                          "
                          class="car-license-plate"
                        >
                          {{ userCar.node.licensePlate }}
                        </div>
                      </div>
                    </td>
                    <td v-else>-</td>
                    <td>
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <router-link
                          :to="{
                            path: '/car/edit/' + userCar.node.id + '/' + number,
                          }"
                          class="click-area-extend"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-secondary"
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="totalRowsCars === 0">
                    <td colspan="6" align="center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="p-3 row">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <div>
                  Total Cars: <b>{{ totalRowsCars }}</b>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                <pagination
                  :currentPage="currentPage"
                  :perPage="perPage"
                  :totalRows="totalRowsCars"
                  @changePage="handlePageChangeCustomerCar"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-2">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
                mb-2
              "
            >
              <h5 class="font-weight-bold">Orders</h5>
              <div class="d-flex">
                <div class="mr-3">
                  <search
                    :search="searchOrders"
                    :placeholder="'Search'"
                    @searchFilter="handleSearchCustomerOrder"
                    :isOrder="true"
                  >
                  </search>
                </div>

                <router-link
                  :to="{ path: '/ordernew' }"
                  class="
                    btn btn-success btn-sm
                    position-relative
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <div class="white-space">Add Order</div>
                </router-link>
              </div>
            </div>
            <div class="table-responsive table-zoom">
              <table class="table data-table mb-0 table-customer">
                <thead class="table-color-heading">
                  <tr class="">
                    <th scope="col">View</th>
                    <th scope="col">Number</th>
                    <th scope="col">Service</th>
                    <th scope="col">Status</th>
                    <th scope="col" style="text-align: right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="white-space-no-wrap transition-row"
                    v-for="userOrder in userOrders.edges"
                    :key="userOrder.node.number"
                  >
                    <td>
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <router-link
                          :to="'/orderdetails/' + userOrder.node.number"
                          class="click-area-extend"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-secondary"
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </router-link>
                      </div>
                    </td>
                    <td class="table-font">
                      <div title="Click To Copy">
                        <div
                          @click.prevent="
                            copyOrderNumToClipboard(userOrder.node.number)
                          "
                          class="order-num"
                        >
                          {{ userOrder.node.number }}
                        </div>
                      </div>
                    </td>
                    <td class="table-font">
                      <div class="serviceName-div">
                        <span
                          v-for="(lines, index) in userOrder.node.lines"
                          :key="lines.serviceName"
                          ><span v-if="index != 0">, </span
                          >{{ lines.serviceName }}</span
                        >
                      </div>
                    </td>
                    <td class="table-font">{{ userOrder.node.status }}</td>
                    <td class="table-font text-monospace">
                      {{ userOrder.node.totalInclTax.amount }}
                    </td>
                  </tr>
                  <tr v-if="totalRowsOrders === 0">
                    <td colspan="6" align="center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="p-3 row">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <div>
                  Total Orders: <b>{{ totalRowsOrders }}</b>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                <pagination
                  :currentPage="currentPageOrder"
                  :perPage="perPage"
                  :totalRows="totalRowsOrders"
                  @changePage="handlePageChangeCustomerOrder"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-2">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
                mb-2
              "
            >
              <h5 class="font-weight-bold">Subscriptions</h5>
              <div class="d-flex">
                <div class="mr-0">
                  <search
                    :search="searchSubs"
                    :placeholder="'Search'"
                    @searchFilter="handleSearchCustomerSubs"
                    :isSubs="true"
                  >
                  </search>
                </div>
              </div>
            </div>
            <div class="table-responsive table-zoom">
              <table class="table data-table mb-0 table-customer">
                <thead class="table-color-heading">
                  <tr class="">
                    <th scope="col">Number</th>
                    <th scope="col">Status</th>
                    <th scope="col">Package Type</th>
                    <th scope="col" style="text-align: right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="white-space-no-wrap transition-row"
                    v-for="userSubs in userSubsciprtion.edges"
                    :key="userSubs.node.number"
                  >
                    <td class="table-font">
                      <div title="Click To Copy">
                        <div
                          @click.prevent="
                            copySubsNumToClipboard(userSubs.node.number)
                          "
                          class="order-num"
                        >
                          {{ userSubs.node.number }}
                        </div>
                      </div>
                    </td>
                    <td class="table-font">{{ userSubs.node.status }}</td>
                    <td class="table-font">
                      {{ userSubs.node.package.type.slug }}
                    </td>
                    <td class="table-font text-monospace">
                      {{ userSubs.node.total.amount }}
                    </td>
                  </tr>
                  <tr v-if="totalRowsSubs === 0">
                    <td colspan="6" align="center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="p-3 row">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <div>
                  Total Subscriptions: <b>{{ totalRowsSubs }}</b>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                <pagination
                  :currentPage="currentPageSubs"
                  :perPage="perPage"
                  :totalRows="totalRowsSubs"
                  @changePage="handlePageChangeCustomerSubs"
                ></pagination>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="customer-voucher-container" >
          <div class="card-body p-2">
            <!-- <div class="d-flex flex-wrap align-items-center justify-content-between mb-3">
              <h5 class="font-weight-bold">Vouchers</h5>
              <div class="d-flex">
                <div class="d-flex justify-content-between">
                  <label
                    class="
                      form-label
                      text-muted
                      filter-label-heading
                      white-space
                      mr-2
                      is-active-text
                    "
                    >Is Active
                  </label>
                  <b-form-checkbox
                    size="lg"
                    v-model="checkedIsActiveVoucher"
                    @input="handleIsActiveVoucher"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                  
                </div>
                <div class="mr-3">
                  <div class="sorting-div">
                      <sortingText
                        :sortingData="voucherSortingData"
                        :sorting="voucherSorting"
                        @changeSorting="onChangeSortingCustomerVoucher"
                      ></sortingText>
                    </div> 
                </div>
                <div class="mr-0">
                  <search
                    :search="searchVoucher"
                    :placeholder="'Search'"
                    @searchFilter="handleSearchCustomerVoucher"
                    :isVoucher="true"
                  >
                  </search>
                </div>         
              </div>
            </div> -->

            <div
              class="
                mb-2
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <h5>Vouchers</h5>
              <div class="d-flex flex-wrap mt-2 mobile-align-customer">
                <div class="d-flex justify-content-between is-active-div">
                  <label
                    class="
                      form-label
                      text-muted
                      filter-label-heading
                      white-space
                      mr-2
                      is-active-text
                    "
                    >Is Active
                  </label>
                  <b-form-checkbox
                    size="lg"
                    v-model="checkedIsActiveVoucher"
                    @input="handleIsActiveVoucher"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>

                <div class="sorting-div">
                  <sortingText
                    :sortingData="voucherSortingData"
                    :sorting="voucherSorting"
                    @changeSorting="onChangeSortingCustomerVoucher"
                  ></sortingText>
                </div>

                <div class="d-flex search-div">
                  <div class="ml-2 filter-div">
                    <search
                      :search="searchVoucher"
                      :placeholder="'Search'"
                      @searchFilter="handleSearchCustomerVoucher"
                      :isVoucher="true"
                    >
                    </search>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive table-zoom">
              <table class="table data-table mb-0 table-customer">
                <thead class="table-color-heading">
                  <tr class="">
                    <!-- <th scope="col">View</th> -->
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">End Date</th>
                    <th scope="col" style="text-align: center">Active</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Used</th>
                    <th scope="col">Usage Limit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="white-space-no-wrap transition-row"
                    v-for="userVouchers in userVoucher.edges"
                    :key="userVouchers.node.id"
                  >
                    <td class="table-font">
                      <div title="Click To Copy">
                        <div
                          @click.prevent="
                            copyVoucherNumToClipboard(userVouchers.node.code)
                          "
                          class="voucher-code"
                        >
                          <!-- <img :src="tickSvg" v-if="userVouchers.node.isFromSubscription"> -->
                          {{ userVouchers.node.code }}
                        </div>
                        <div>
                          <img
                            :src="proImg"
                            width="20px"
                            height="20px"
                            v-if="userVouchers.node.isFromSubscription"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="table-font" v-if="userVouchers.node.name">
                      <div :title="userVouchers.node.name" class="voucher-name">
                        {{ userVouchers.node.name }}
                      </div>
                    </td>
                    <td class="table-font" v-if="userVouchers.node.endDate">
                      {{ userVouchers.node.endDate | dateDayFormat }}{{ ", "
                      }}{{ userVouchers.node.endDate | timeFormat }}
                    </td>
                    <td v-else>-</td>
                    <td
                      class="table-font"
                      style="text-align: center"
                      v-if="userVouchers.node.isExpired === true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="red"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                        />
                      </svg>
                    </td>
                    <td v-else style="text-align: center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="green"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
                        />
                      </svg>
                    </td>
                    <td class="table-font">
                      {{ userVouchers.node.discountValue }}
                    </td>
                    <td class="table-font">
                      {{ userVouchers.node.used }}
                    </td>
                    <td class="table-font" v-if="userVouchers.node.usageLimit">
                      {{ userVouchers.node.usageLimit }}
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr v-if="totalRowsVoucher === 0">
                    <td colspan="6" align="center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="p-3 row">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <div>
                  Total Vouchers: <b>{{ totalRowsVoucher }}</b>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                <pagination
                  :currentPage="currentPageVoucher"
                  :perPage="perPage"
                  :totalRows="totalRowsVoucher"
                  @changePage="handlePageChangeCustomerVoucher"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import {
  GET_CUSTOMERS_DETAILS,
  GET_CUSTOMERS_CARS,
  GET_CUSTOMERS_ORDERS,
  GET_CUSTOMERS_SUBSCRIPTIONS,
  GET_CUSTOMERS_VOUCHERS,
} from "../../../graphql/query";
import search from "../../../components/filters/search.vue";
import pagination from "../../../components/pagination/pagination.vue";
import sortingText from "../../../components/filters/sorting.vue";

import * as moment from "moment";
import "moment-duration-format";
export default {
  name: "CustomerView",
  props: ["number"],
  components: { pagination, search, sortingText },
  data() {
    return {
      user: [],
      // activeTabCars: true,
      // activeTabOrders: false,

      userCars: [],
      totalRowsCars: 1,
      currentPage: 1,
      perPage: 5,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      searchCars: null,

      userOrders: [],
      totalRowsOrders: 1,
      currentPageOrder: 1,
      afterOrder: null,
      beforeOrder: null,
      hasNextPageOrder: true,
      hasPreviousPageOrder: false,
      lastOrder: null,
      firstOrder: null,
      searchOrders: null,
      lines: [],
      serviceName: [],

      userSubsciprtion: [],
      totalRowsSubs: 1,
      currentPageSubs: 1,
      afterSubs: null,
      beforeSubs: null,
      hasNextPageSubs: true,
      hasPreviousPageSubs: false,
      lastSubs: null,
      firstSubs: null,
      searchSubs: null,

      userVoucher: [],
      totalRowsVoucher: 1,
      currentPageVoucher: 1,
      afterVoucher: null,
      beforeVoucher: null,
      hasNextPageVoucher: true,
      hasPreviousPageVoucher: false,
      lastVoucher: null,
      firstVoucher: null,
      searchVoucher: null,
      proImg: require("../../../assets/images/logo-pro.png"),
      voucherSortingData: [
        { id: "START_DATE - ASC", name: "Start Date - Asc." },
        { id: "START_DATE - DESC", name: "Start Date - Desc." },
        { id: "END_DATE - ASC", name: "End Date - Asc." },
        { id: "END_DATE - DESC", name: "End Date - Desc." },
        { id: "CREATED_AT - ASC", name: "Created At - Asc." },
        { id: "CREATED_AT - DESC", name: "Created At - Desc." },
        { id: "UPDATED_AT - ASC", name: "Update At - Asc." },
        { id: "UPDATED_AT - DESC", name: "Update At - Desc." },
      ],
      voucherSorting: { id: "CREATED_AT - DESC", name: "Created At - Desc." },
      directionVoucher: "",
      fieldVoucher: "",
      checkedIsActiveVoucher: false,
      isActiveVoucher: true,

      notes: [],
    };
  },
  mounted() {
    // Set the initial number of items
    this.first = this.perPage;
    this.firstOrder = this.perPage;
    this.firstSubs = this.perPage;
    this.firstVoucher = this.perPage;
    this.getCustomerDetails();
    this.getCustomerCars();
    this.getCustomerOrders();
    this.getCustomerSubscriptions();
    (this.directionVoucher = "DESC"), (this.fieldVoucher = "CREATED_AT");
    this.getCustomerVouchers();
  },
  methods: {
    getCustomerDetails() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_DETAILS,
          variables: {
            id: this.number,
          },
        })
        .then((data) => {
          this.user = data.data.bwsDashboardUser;
          if (data.data.bwsDashboardUser.profile.notes) {
            var data_notes = data.data.bwsDashboardUser.profile.notes.split("\n");
            for (var i = 0; i < data_notes.length; i++) {
              if (data_notes[i].includes("-")) {
                var note_data = data_notes[i].split(" - ");
                // note.time = moment(String(note_data[0])).format('DD MMMM YYYY hh:mm A');
                // note.text = note_data[1];
                this.notes.push(
                  note_data[1] +
                    " - " +
                    moment(String(note_data[0])).format("DD/MM/YYYY hh:mm A")
                );
              } else {
                this.notes.push(data_notes[i]);
              }
            }
          }
        });
    },
    //Get Customer Cars
    getCustomerCars() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_CARS,
          variables: {
            userId: this.number,
            first: this.first,
            last: this.last,
            after: this.after,
            before: this.before,
            search: this.searchCars,
          },
        })
        .then((data) => {
          this.userCars = data.data.bwsDashboarduserCars;
          this.totalRowsCars = data.data.bwsDashboarduserCars.totalCount;
          this.after = data.data.bwsDashboarduserCars.pageInfo.endCursor;
          this.before = data.data.bwsDashboarduserCars.pageInfo.startCursor;
          this.hasNextPage = data.data.bwsDashboarduserCars.pageInfo.hasNextPage;
          this.hasPreviousPage = data.data.bwsDashboarduserCars.pageInfo.hasPreviousPage;
        });
    },
    //Pagination Customer Car
    handlePageChangeCustomerCar(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getCustomerCars();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getCustomerCars();
      }
    },

    //Searching Customer Car
    handleSearchCustomerCar(event) {
      this.searchCars = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.getCustomerCars();
    },

    //Get Customer Orders
    getCustomerOrders() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_ORDERS,
          variables: {
            userId: this.number,
            first: this.firstOrder,
            last: this.lastOrder,
            after: this.afterOrder,
            before: this.beforeOrder,
            search: this.searchOrders,
          },
        })
        .then((data) => {
          this.userOrders = data.data.userOrders;
          this.totalRowsOrders = data.data.userOrders.totalCount;
          this.afterOrder = data.data.userOrders.pageInfo.endCursor;
          this.beforeOrder = data.data.userOrders.pageInfo.startCursor;
          this.hasNextPageOrder = data.data.userOrders.pageInfo.hasNextPage;
          this.hasPreviousPageOrder =
            data.data.userOrders.pageInfo.hasPreviousPage;

          this.userOrders.edges.map((res) => {
            this.lines.push(res.node.lines);
            return this.lines;
          });
        });
    },

    //Pagination Customer Order
    handlePageChangeCustomerOrder(event) {
      if (event > this.currentPageOrder && this.hasNextPageOrder) {
        this.currentPageOrder = event;
        this.beforeOrder = null;
        this.lastOrder = null;
        this.firstOrder = this.perPage;
        this.afterOrder;
        this.getCustomerOrders();
      } else if (event < this.currentPageOrder && this.hasPreviousPageOrder) {
        this.currentPageOrder = event;
        this.afterOrder = null;
        this.beforeOrder;
        this.lastOrder = this.perPage;
        this.firstOrder = null;
        this.getCustomerOrders();
      }
    },

    //Searching Customer Order
    handleSearchCustomerOrder(event) {
      this.searchOrders = event;
      this.afterOrder = null;
      this.beforeOrder = null;
      this.lastOrder = null;
      this.firstOrder = this.perPage;
      this.currentPageOrder = 1;
      this.getCustomerOrders();
    },

    copyLicensePlateToClipboard(licensePlateNo) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = licensePlateNo;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(
        `License Plate: ` + licensePlateNo + " " + `${message}`,
        {
          toaster: "b-toaster-top-center",
          variant: "success",
          solid: true,
          toastClass: "text-center toast-box toast-style",
        }
      );
    },

    copyOrderNumToClipboard(orderNo) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = orderNo;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(`Order Number: ` + orderNo + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
    handleLocationHistoryLink(id) {
      var location = document.getElementById(id).innerText;
      location = location.replace(/\s+/g, "");
      let url = "https://www.google.com/maps/search/?api=1&query=" + location;
      window.open(url, "_blank");
    },

    getCustomerSubscriptions() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_SUBSCRIPTIONS,
          variables: {
            userId: this.number,
            first: this.firstSubs,
            last: this.lastSubs,
            after: this.afterSubs,
            before: this.beforeSubs,
            search: this.searchSubs,
          },
        })
        .then((data) => {
          this.userSubsciprtion = data.data.userSubscriptions;
          this.totalRowsSubs = data.data.userSubscriptions.totalCount;
          this.afterSubs = data.data.userSubscriptions.pageInfo.endCursor;
          this.beforeSubs = data.data.userSubscriptions.pageInfo.startCursor;
          this.hasNextPageSubs =
            data.data.userSubscriptions.pageInfo.hasNextPage;
          this.hasPreviousPageSubs =
            data.data.userSubscriptions.pageInfo.hasPreviousPage;
        });
    },

    //Pagination Customer Subscription
    handlePageChangeCustomerSubs(event) {
      if (event > this.currentPageSubs && this.hasNextPageSubs) {
        this.currentPageSubs = event;
        this.beforeSubs = null;
        this.lastSubs = null;
        this.firstSubs = this.perPage;
        this.afterSubs;
        this.getCustomerSubscriptions();
      } else if (event < this.currentPageSubs && this.hasPreviousPageSubs) {
        this.currentPageSubs = event;
        this.afterSubs = null;
        this.beforeSubs;
        this.lastSubs = this.perPage;
        this.firstSubs = null;
        this.getCustomerSubscriptions();
      }
    },

    //Searching Customer Subscription
    handleSearchCustomerSubs(event) {
      this.searchSubs = event;
      this.afterSubs = null;
      this.beforeSubs = null;
      this.lastSubs = null;
      this.firstSubs = this.perPage;
      this.currentPageSubs = 1;
      this.getCustomerSubscriptions();
    },

    copySubsNumToClipboard(orderNo) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = orderNo;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(
        `Subscription Number: ` + orderNo + " " + `${message}`,
        {
          toaster: "b-toaster-top-center",
          variant: "success",
          solid: true,
          toastClass: "text-center toast-box toast-style",
        }
      );
    },

    getCustomerVouchers() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_VOUCHERS,
          variables: {
            userId: this.number,
            first: this.firstVoucher,
            last: this.lastVoucher,
            after: this.afterVoucher,
            before: this.beforeVoucher,
            search: this.searchVoucher,
            direction: this.directionVoucher,
            field: this.fieldVoucher,
            isExpired: this.isActiveVoucher,
          },
        })
        .then((data) => {
          this.userVoucher = data.data.userVouchers;
          this.totalRowsVoucher = data.data.userVouchers.totalCount;
          this.afterVoucher = data.data.userVouchers.pageInfo.endCursor;
          this.beforeVoucher = data.data.userVouchers.pageInfo.startCursor;
          this.hasNextPageVoucher = data.data.userVouchers.pageInfo.hasNextPage;
          this.hasPreviousPageVoucher =
            data.data.userVouchers.pageInfo.hasPreviousPage;
        });
    },

    //Pagination Customer Voucher
    handlePageChangeCustomerVoucher(event) {
      if (event > this.currentPageVoucher && this.hasNextPageVoucher) {
        this.currentPageVoucher = event;
        this.beforeVoucher = null;
        this.lastVoucher = null;
        this.firstVoucher = this.perPage;
        this.afterVoucher;
        this.getCustomerVouchers();
      } else if (
        event < this.currentPageVoucher &&
        this.hasPreviousPageVoucher
      ) {
        this.currentPageVoucher = event;
        this.afterVoucher = null;
        this.beforeVoucher;
        this.lastVoucher = this.perPage;
        this.firstVoucher = null;
        this.getCustomerVouchers();
      }
    },

    //Searching Customer Voucher
    handleSearchCustomerVoucher(event) {
      this.searchVoucher = event;
      this.afterVoucher = null;
      this.beforeVoucher = null;
      this.lastVoucher = null;
      this.firstVoucher = this.perPage;
      this.currentPageVoucher = 1;
      this.getCustomerVouchers();
    },

    //Sorting Customer Voucher
    onChangeSortingCustomerVoucher(event) {
      if (event.id !== null) {
        var res = event.id.split("-");
        this.fieldVoucher = res[0].replaceAll(" ", "");
        this.directionVoucher = res[1].replaceAll(" ", "");
        this.beforeVoucher = null;
        this.afterVoucher = null;
        this.currentPageVoucher = 1;
        this.firstVoucher = this.perPage;
        this.lastVoucher = null;
        // this.orderFilters.sort_by = this.field +' - '+this.direction;
        // this.changeOrderFilters(this.orderFilters);
        this.getCustomerVouchers();
      } else {
        (this.directionVoucher = "DESC"), (this.fieldVoucher = "CREATED_AT");
        // this.orderFilters.sort_by = this.field +' - '+this.direction;
        // this.changeOrderFilters(this.orderFilters);
        this.beforeVoucher = null;
        this.afterVoucher = null;
        this.firstVoucher = this.perPage;
        this.lastVoucher = null;
        this.currentPageVoucher = 1;
        this.getCustomerVouchers();
      }
    },

    //Active Filter for Customer Voucher
    handleIsActiveVoucher() {
      this.isActiveVoucher = !this.checkedIsActiveVoucher;
      this.afterVoucher = null;
      this.beforeVoucher = null;
      this.lastVoucher = null;
      this.firstVoucher = this.perPage;
      this.currentPageVoucher = 1;
      this.getCustomerVouchers();
    },

    copyVoucherNumToClipboard(orderNo) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = orderNo;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(`Voucher Number: ` + orderNo + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
    // handleBackBtn() {
    //   this.$router.go(-1);
    // },
  },
};
</script>
<style>
#carTable thead tr,
#orderTable thead tr {
  background-color: rgba(51, 120, 255, 0.05);
}
/* table.table-customer {
    text-align: center;
} */
.table.table-customer th,
.table.table-customer td {
  padding: 12px 12px;
}
.custom-search-input {
  height: 30px !important;
}

.table th.th-padding-class {
  padding: 0px;
}
.white-space {
  overflow: auto;
  white-space: nowrap;
}

/* .table-zoom {
  zoom: 90%;
} */

.toast-style {
  font-size: 1rem;
  padding: 10px;
}

.car-license-plate:hover,
.order-num:hover {
  color: blue;
  cursor: pointer;
}

.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.serviceName-div {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voucher-name {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voucher-code {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.text-monospace {
  font-family: monospace, monospace !important;
  text-align: right;
}

.dummy-email {
  background: #f10000d1;
  padding: 2px;
  border-radius: 3px;
  color: white !important;
}

.pre {
  padding: 2px 0px;
  font-size: 15px;
}

.singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.multiselect {
  min-height: 10px !important;
}

.is-active-text {
  font-size: 0.94rem;
  display: flex;
  align-items: center;
}

#customer-voucher-container .sorting-div .singleOptions .multiselect__tags{
  width: 170px; 
}


.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}
.multiselect__select {
  height: 31px !important;
}
.filter-label-heading {
  font-weight: 600;
  font-size: 0.9rem;
}

@media only screen and (max-width: 768px) {
  .table.table-customer {
    display: table;
  }
  .table.table-customer th,
  .table.table-customer td {
    /* padding: 10px 5px; */
    padding: 12px 12px;
  }
}

@media only screen and (max-width: 528px) {
  .search-div{
    margin-top: 1rem;
  }
  
   /* .mobile-align-customer {
    justify-content: flex-start;
  } */

}

@media only screen and (max-width: 417px) {
   .filter-div{
    margin-left: 0rem !important;
  }
}

@media only screen and (max-width: 332px) {
  .is-active-div{
    margin-bottom: 1rem;
  }
}
</style>

