<template>
    <!-- <vue-single-select
        v-model="sortText"
        :options="sortingData"
        option-label="name"
        option-key="id"
        value="id"
       :placeholder="placeholder"
        :getOptionDescription="getCustomSorting"
        @input="changeSorting($event)"
     
        name="sorting"
    >
    </vue-single-select> -->
     <multiselect
        v-model="sortText"
        :options="sortingData"
        :max="sortingData.length"
         :custom-label="getCustomSorting"
        @select="changeSorting"
        :class="{ singleOptions: true }"
        :show-labels="false" 
         :allow-empty="false"
        track-by="name"
      >
      </multiselect>

</template>

<script>
export default {
  name: 'sorting',
  props: ['sortingData','sorting', 'placeholder'],
  data() {
      return {
       
       sortText: this.sorting
   };
  },
  methods:{
    getCustomSorting(opt){
        return opt.name;
    },
    changeSorting(event) {
      this.$emit('changeSorting',event)
    },
  }
}
</script>
<style>
/* .sorting-div .search-input{
  padding: 0.375em 1.55em !important;
} */

.sorting-div .singleOptions .multiselect__tags{
  /* min-height: 20px; */
  /* width: 231px; */
  /* min-height: 40px;*/
  width: 200px; 
}


</style>

