<template>
  <b-col sm="7" md="4" class="my-1">
    <b-pagination
      :disabled="disablePagination"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="fill"
      size="sm"
      class="my-0"
      prev-text="Prev"
      next-text="Next"
      limit="1"
      @change="changePage($event)"
    ></b-pagination>
  </b-col>
</template>

<script>
export default {
  name: "pagination",
  props: ["totalRows", "currentPage", "perPage"],
  data: function () {
    return {
      disablePagination: false,
      // currentPageData: this.currentPage
      // mutableList: this.currentPage
    };
  },
  methods: {
    changePage(event) {
      this.disablePagination = true

      setTimeout(() => {
        this.disablePagination = false
      }, 250);

      this.$emit("changePage", event);
    },
  },
};
</script>
<style>
.page-item.active,
.pagination-sm .page-item:first-child .page-link,
.pagination-sm .page-item:last-child .page-link {
  display: none !important;
}
</style>